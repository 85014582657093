// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sp-nw-2022__price-grid-2c___cg87S{display:grid}@media(max-width: 600px){.sp-nw-2022__price-grid-2c___cg87S{grid-template-columns:2fr 1fr;grid-gap:8px 8px}}@media(min-width: 601px){.sp-nw-2022__price-grid-2c___cg87S{grid-template-columns:1fr 1fr;grid-gap:8px 8px}}", "",{"version":3,"sources":["webpack://./public/static/css/min/sp-nw-2022.css"],"names":[],"mappings":"AAAA,mCAAA,YAAA,CAAA,yBAAA,mCAAA,6BAAA,CAAA,gBAAA,CAAA,CAAA,yBAAA,mCAAA,6BAAA,CAAA,gBAAA,CAAA","sourcesContent":[".price-grid-2c{display:grid}@media (max-width:600px){.price-grid-2c{grid-template-columns:2fr 1fr;grid-gap:8px 8px}}@media (min-width:601px){.price-grid-2c{grid-template-columns:1fr 1fr;grid-gap:8px 8px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price-grid-2c": "sp-nw-2022__price-grid-2c___cg87S"
};
export default ___CSS_LOADER_EXPORT___;
