// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressBar-module__progressBar_external___7Rxp0{box-sizing:border-box;position:relative;width:100%;height:15px;line-height:15px;background-color:#d3d3d3;color:#000;font-family:system-ui;font-weight:bold;border-radius:8px}.ProgressBar-module__progressBar_internal___k1zfo{font-size:.75em;height:100%;box-sizing:border-box;white-space:pre;max-width:100%;min-width:22px;transition:width .3s ease-in;padding:0 4px 0 4px;border-radius:8px;display:flex;gap:8px;justify-content:space-between;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/ClientPerfWidget/ProgressBar.module.scss"],"names":[],"mappings":"AACE,kDACE,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,wBAAA,CACA,UAAA,CACA,qBAAA,CACA,gBAAA,CACA,iBAAA,CAEF,kDACE,eAAA,CACA,WAAA,CACA,qBAAA,CACA,eAAA,CAWA,cAAA,CACA,cAAA,CACA,4BAAA,CACA,mBAAA,CACA,iBAAA,CAEA,YAAA,CACA,OAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".progressBar {\n  &_external {\n    box-sizing: border-box;\n    position: relative;\n    width: 100%;\n    height: 15px;\n    line-height: 15px;\n    background-color: lightgray;\n    color: #000;\n    font-family: system-ui;\n    font-weight: bold;\n    border-radius: 8px;\n  }\n  &_internal {\n    font-size: 0.75em;\n    height: 100%;\n    box-sizing: border-box;\n    white-space: pre;\n    // content: `\\'${label}\\'`,\n    // background-color:\n    //   value < 0\n    //     ? colorSubZero\n    //     : value >= 0 && value < limits.warning\n    //     ? colorNormal\n    //     : value >= limits.warning && value < limits.danger\n    //     ? colorWarning\n    //     : colorDanger,\n    // width: `${value <= 100 ? Math.abs(value).toFixed(0) : 100}%`,\n    max-width: 100%;\n    min-width: 22px;\n    transition: width 0.3s ease-in;\n    padding: 0 4px 0 4px;\n    border-radius: 8px;\n\n    display: flex;\n    gap: 8px;\n    justify-content: space-between;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar_external": "ProgressBar-module__progressBar_external___7Rxp0",
	"progressBar_internal": "ProgressBar-module__progressBar_internal___k1zfo"
};
export default ___CSS_LOADER_EXPORT___;
