import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeColor } from '~/ui-kit.uremont/Theme'

const sizeVariations = {
  '14px mobile': { width: '14', height: '14' },
}

const getSVGWidth = (sizeVariation) => sizeVariations[sizeVariation].width
const getSVGHeight = (sizeVariation) => sizeVariations[sizeVariation].height

const StyledSVG = styled('svg')`
  & path {
    fill: ${(p) => themeColor(p.fillColorName)};
  }
`

export const PhoneIcon = ({ colorName = 'Primary Dark Blue', sizeVariation = '14px mobile' }) => (
  <StyledSVG
    fillColorName={colorName}
    width={getSVGWidth(sizeVariation)}
    height={getSVGHeight(sizeVariation)}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.344446 7.08551C0.928471 8.22062 1.79722 9.43098 2.79082 10.4926C3.62864 11.3874 4.57381 12.1795 5.5995 12.8462L5.74361 12.9401C6.50442 13.4371 7.36616 14 8.36443 14C8.36501 14 8.38193 14 8.38252 14C9.21042 13.9942 10.0214 13.6558 10.6072 13.0702L11.4141 12.2647C11.7496 11.9287 11.7496 11.3821 11.4141 11.0461L9.80203 9.43506C9.47647 9.10958 8.90878 9.10899 8.58322 9.43506L7.54294 10.4745C7.45018 10.5673 7.29207 10.5842 7.18705 10.5118C5.73544 9.50739 4.48921 8.26204 3.48394 6.81078C3.40985 6.70345 3.4256 6.55062 3.52128 6.45496L4.56156 5.41551C4.72434 5.25277 4.81419 5.03636 4.81419 4.80654C4.81419 4.57614 4.72434 4.35973 4.56156 4.19757L2.94893 2.5859C2.62278 2.26159 2.05568 2.261 1.73012 2.5859L0.923804 3.39203C0.77736 3.53844 0.643752 3.7006 0.52823 3.87442C-0.10247 4.81646 -0.174233 6.07756 0.344446 7.08551ZM1.01319 4.19707C1.10829 4.05533 1.21681 3.92292 1.33642 3.80334L2.14274 2.9978C2.19525 2.9453 2.26526 2.91613 2.33994 2.91613C2.41462 2.91613 2.48463 2.9453 2.53656 2.9978L4.14919 4.60946C4.2017 4.66196 4.23087 4.73138 4.23087 4.80604C4.23087 4.8807 4.2017 4.95011 4.14919 5.00261L3.10892 6.04206C2.81194 6.33896 2.76819 6.80152 3.0039 7.14217C4.04942 8.65118 5.34466 9.94611 6.85461 10.9908C6.99697 11.0894 7.16442 11.1413 7.33886 11.1413C7.56874 11.1413 7.79337 11.048 7.95556 10.8858L8.99584 9.84637C9.10144 9.74079 9.28406 9.74079 9.38966 9.84637L11.0017 11.4575C11.1102 11.5659 11.1102 11.7427 11.0017 11.8512L10.1954 12.6567C9.78648 13.0655 9.24255 13.327 8.67038 13.3968C8.50779 13.4167 8.37299 13.5437 8.36514 13.7073C8.36514 13.5462 8.23398 13.4173 8.07437 13.3957C7.36268 13.2993 6.70356 12.8687 6.06346 12.4508L5.91818 12.3557C4.93159 11.7147 4.02258 10.9535 3.21744 10.0931C2.25942 9.06999 1.42394 7.90688 0.863833 6.81785C0.440255 5.99481 0.498599 4.96645 1.01319 4.19707Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16559 0C8.00456 0 7.87387 0.13066 7.87387 0.291652C7.87387 0.452644 8.00456 0.583304 8.16559 0.583304C11.0606 0.583304 13.4166 2.93869 13.4166 5.83304C13.4166 5.99403 13.5472 6.12469 13.7083 6.12469C13.8693 6.12469 14 5.99403 14 5.83304C14 2.6167 11.3827 0 8.16559 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6662 5.83313C11.6662 5.99412 11.7969 6.12478 11.958 6.12478C12.119 6.12478 12.2497 5.99412 12.2497 5.83313C12.2497 3.58158 10.4177 1.75 8.16559 1.75C8.00456 1.75 7.87387 1.88066 7.87387 2.04165C7.87387 2.20265 8.00456 2.33331 8.16559 2.33331C10.0962 2.33331 11.6662 3.90298 11.6662 5.83313Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87387 3.79123C7.87387 3.95222 8.00456 4.08288 8.16559 4.08288C9.1306 4.08288 9.91591 4.86801 9.91591 5.83279C9.91591 5.99378 10.0466 6.12444 10.2076 6.12444C10.3687 6.12444 10.4994 5.99378 10.4994 5.83279C10.4994 4.54602 9.45266 3.49958 8.16559 3.49958C8.00456 3.49958 7.87387 3.63024 7.87387 3.79123Z"
    />
  </StyledSVG>
)

PhoneIcon.propTypes = {
  sizeVariation: PropTypes.oneOf(Object.keys(sizeVariations)),
  colorName: PropTypes.string,
}

PhoneIcon.defaultProps = {
  sizeVariation: '14px mobile',
  colorName: 'Primary Dark Blue',
}
