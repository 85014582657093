const legacyColors = {
  orange: '#FF9000',
  blue: '#365374', // #2196f3 mui
  lightBlue: '#00B9FF',
  cobalt: '#00579F',
  backgroundLightBlue: '#e4f7fe',
  gray: '#7A7E81',
  black: '#000000',
  white: '#FFFFFF',
  darkDarkBlue: '#193351',
  superGray: '#333333',
  darkBlue: '#323643',
  darkLight: '#5E5E5E',
  green: '#27AE60',
  rgs: '#9E0A19',
  rgsGold: '#C29A5C',
  rgsLight: '#F9F5EF',
  rgsGray: '#AFAFAF',
  lightGrayStroke: '#D7D7D7',
  primaryDark: '#003366',
  // primaryDark: '#0162c8',
  legacyHeader: '#0399d0',
  1: '#436386',
  2: '#0095CA',
  3: '#00B9FF',
  4: '#436386',
  5: '#0095CA',
  6: '#00B9FF',
  7: '#00BCFF',
}

const mainStyleReference = {
  colors: {
    'Primary Dark Blue': '#003366',
    // 'Primary Dark Blue': '#0162c8',
    'Light Gray BG': '#f8f8f8',
    Light: '#ffffff',
    'Dark Black': '#333333',
    'Select Blue': '#015bd7',
    // 'Select Blue': '#003366',
    'White color': '#fff',
    'Accent Orange': '#ff9000',
    'Gray-blue': '#576373',
    'Accent Orange Hover': '#ff781e',
    'Accent Blue light': '#01AEEF',
    Succes: '#32aa37',
    'Light Gray Stroke': '#d7d7d7',
    'Light blue': '#94a3b3',
    Incorrect: '#d32b44',
    'Private color Dark blue': '#18244d',
    White: '#ffffff',
    '3rd gray BD': '#bdbcbc',
    primary: '#015bd7',
    // primary: '#003366',
    secondary: '#ff781e',
    'Transparent Gray-blue': 'rgba(173, 176, 181, 0.55)',
    'Transparent Black-hard': 'rgba(0, 0, 0, 0.7)',
    'Accent Orange Hover Transparent 15%': 'rgba(255, 120, 30, 0.15)',
    'Success Transparent 15%': 'rgba(50, 170, 55, 0.15)',
    'Light blue Transparent 15%': 'rgba(148, 163, 179, 0.15)',
    'Incorrect Transparent 15%': 'rgba(211, 43, 68, 0.15)',
    'IBM blue': '#1E7BF6',
    'Primary blue': '#0056C7',
    'bw 800': '#2B2B2B',
  },
  textStyles: {
    '12R Notifications': {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 18,
    },
    '32B H1': {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 45,
    },
    '24Semi H2': {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 36,
    },
    '50B Title': {
      fontWeight: 700,
      fontSize: 50,
      lineHeight: 70,
    },
    '18R SubTitle': {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 27,
    },
    '14R Small-body': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 21,
    },
    '16R Body': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 24,
    },
    '16M Subtitle': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 24,
    },
    'Link - 14pt': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 14,
    },
    '14Medium Sub': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 21,
    },
    '14SemiBold Sub': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 21,
    },
    'Mobile Title': {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 34,
    },
    'Tablet 40B Title': {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 56,
    },
    'Mobile 20Semi H2': {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 30,
    },
    'Mobile H1': {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 34,
    },
    '20B H3': {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 30,
    },
    'Mobile 18B H3': {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 27,
    },
    '18B H4': {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 27,
    },
    'Mobile H4 Bold, 16px': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 24,
    },
    'Mobile 16R Subtitle ': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 24,
    },
    '16B Subtitle': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 24,
    },
    '12Semi Note tags': {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 18,
    },
    '14Bold Sub': {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 21,
    },
    'Title 2': {
      fontFamily: 'IBM Plex Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: 28,
    },
    Text: {
      fontFamily: 'IBM Plex Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: 24,
    },
    description: {
      fontFamily: 'IBM Plex Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: 16,
    },
    Tips: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: 36,
    },
  },
  fontFamilies: {
    main: 'Montserrat, sans-serif',
    rgs: 'Open Sans, sans-serif',
  },
  shadows: {
    'Main Shadow': '0px 0px 10px rgba(83, 98, 124, 0.2)',
    'Add shadow': '0px 0px 10px rgba(83, 98, 124, 0.3)',
    'Small shadow notification': '0px 0px 4px rgba(83, 98, 124, 0.2)',
    'Blur shadow': '0px 0px 20px rgba(83, 98, 124, 0.2)',
    'Dropdown shadow': '0px 4px 10px rgba(83, 98, 124, 0.1)',
    'default shadow': '0px 1px 2px rgba(0, 0, 0, 0.18)',
    'default shadow hover': '0px 6px 8px rgba(194, 196, 196, 0.25)',
  },
}

const mediaQueries = {
  mobile: { min: '240px', max: '767px' },
  tablet: { min: '768px', max: '1024px' },
  laptop: { min: '1025px', max: '1279px' },
  desktop: { min: '1280px', max: '3960px' },
}

export const Theme = {
  ...mainStyleReference,
  colors: {
    ...legacyColors,
    ...mainStyleReference.colors,
  },
  mediaQueries,
}
