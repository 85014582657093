// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width: 900px){.CookiePolicyOffer-module__contentWrapper___vuVab{display:flex;flex-direction:column;align-items:center;justify-content:center;gap:8px}.CookiePolicyOffer-module__content___GhUBQ{padding:10px 20px 10px 20px;text-align:center}}@media(min-width: calc(900px + 1px)){.CookiePolicyOffer-module__contentWrapper___vuVab{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;align-items:center;gap:16px}.CookiePolicyOffer-module__content___GhUBQ{padding:0px}}", "",{"version":3,"sources":["webpack://./src/components/CookiePolicyOffer/CookiePolicyOffer.module.scss"],"names":[],"mappings":"AAEA,yBACE,kDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAEF,2CACE,2BAAA,CACA,iBAAA,CAAA,CAGJ,qCACE,kDACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CAEF,2CACE,WAAA,CAAA","sourcesContent":["@import '~/mui/scss-vars/monitors.scss';\n\n@media (max-width: #{$md}px) {\n  .contentWrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n  }\n  .content {\n    padding: 10px 20px 10px 20px;\n    text-align: center;\n  }\n}\n@media (min-width: calc(#{$md}px + 1px)) {\n  .contentWrapper {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    gap: 16px;\n  }\n  .content {\n    padding: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "CookiePolicyOffer-module__contentWrapper___vuVab",
	"content": "CookiePolicyOffer-module__content___GhUBQ"
};
export default ___CSS_LOADER_EXPORT___;
