import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeColor } from '~/ui-kit.uremont/Theme'

const sizeVariations = {
  '14px mobile': { width: '14', height: '14' },
}

const getSVGWidth = (sizeVariation) => sizeVariations[sizeVariation].width
const getSVGHeight = (sizeVariation) => sizeVariations[sizeVariation].height

const StyledSVG = styled('svg')`
  & path {
    fill: ${(p) => themeColor(p.fillColorName)};
  }
`

export const EvacuatorIcon = ({ colorName = 'Primary Dark Blue', sizeVariation = '14px mobile' }) => (
  <StyledSVG
    fillColorName={colorName}
    width={getSVGWidth(sizeVariation)}
    height={getSVGHeight(sizeVariation)}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.2121 0.995173C10.1564 0.995173 10.1564 0.995173 10.2121 0.995173C9.98943 0.821 9.71112 0.646827 9.43281 0.530712C8.43089 0.0662515 7.31765 -0.107921 6.2044 0.0662515C5.64778 0.124309 5.09115 0.298482 4.59019 0.58877C4.36754 0.704885 4.08923 0.821 3.92225 0.995173C3.75526 1.16935 3.75526 1.34352 3.6996 1.57575C3.6996 1.92409 3.6996 2.27244 3.64393 2.62078C3.64393 2.91107 3.64393 3.25942 3.81092 3.54971C4.20056 4.30445 4.81284 4.88503 5.42513 5.40755C6.03741 5.98812 6.6497 6.33647 7.48463 6.10424C8.2639 5.93007 8.87619 5.29143 9.37715 4.6528C9.87811 4.07222 10.3234 3.43359 10.3234 2.62078C10.3234 2.27244 10.3234 1.98215 10.3234 1.63381C10.3234 1.45963 10.3791 1.16935 10.2121 0.995173ZM9.5998 3.20136C9.37715 3.78194 8.93185 4.30445 8.48655 4.71086C7.92993 5.29143 7.15066 5.75589 6.37139 5.34949C5.70344 5.00114 5.25814 4.30445 4.75718 3.66582C4.59019 3.43359 4.42321 3.20136 4.36754 2.85301C4.31188 2.50467 4.20056 1.80798 4.42321 1.45963C4.53453 1.40158 4.64586 1.34352 4.75718 1.28546C6.14874 0.58877 7.87427 0.472654 9.32149 1.2274C9.43281 1.28546 9.5998 1.40158 9.65546 1.51769C9.76679 1.80798 9.71112 2.3305 9.71112 2.67884C9.71112 2.85301 9.65546 3.02719 9.5998 3.20136Z" />
    <path d="M10.9357 8.83288C10.9357 8.71676 10.8244 8.36842 10.6574 8.02007C10.5461 7.72978 10.3234 7.4395 10.0451 7.38144C9.87814 7.32338 9.71116 7.55561 9.65549 7.72978C9.59983 7.90396 9.48851 8.13619 9.43285 8.48453C9.32152 9.06511 9.32152 9.70374 9.15453 10.2843C8.93189 11.1552 8.09695 11.6777 7.09503 11.6196C6.53841 11.5035 6.09311 11.2132 5.87046 10.8649C5.70347 10.4585 5.75914 9.99403 5.8148 9.7618C5.92612 9.23928 6.2601 8.83288 6.53841 8.42647C6.76106 8.07813 7.20635 7.32338 7.09503 6.91698C6.98371 6.91698 6.87238 6.91698 6.81672 6.97503C6.53841 7.03309 6.31576 6.91698 6.03745 6.80086C5.87046 6.68475 5.70347 6.56863 5.53649 6.51057C5.31384 6.39446 5.25818 6.45252 5.09119 6.56863C4.42324 6.97503 3.92228 7.61367 3.53264 8.31036C3.08735 9.18122 2.8647 10.2263 3.08735 11.2132C3.31 12.2002 3.97794 13.0711 4.86854 13.5355C5.48082 13.8258 6.09311 14 6.76106 14C7.20635 14 7.65165 13.9419 8.04129 13.8258C8.93189 13.5936 9.71116 13.013 10.2678 12.2583C10.6018 11.7938 10.8244 11.2713 10.9357 10.7488C11.0471 10.0521 10.9914 9.47151 10.9357 8.83288ZM7.31768 13.1291C5.87046 13.3614 4.14493 12.6647 3.69963 10.981C3.53264 10.2843 3.64397 9.41345 4.08927 8.60065C4.42324 8.02007 4.86854 7.49755 5.3695 7.14921L5.53649 7.26532C5.70347 7.38144 5.87046 7.49755 6.09311 7.55561C6.14877 7.55561 6.20443 7.61367 6.2601 7.61367C6.20443 7.78784 6.09311 7.90396 5.92612 8.13619L5.87046 8.19424C5.59215 8.60065 5.31384 9.06511 5.20251 9.64568C5.14685 9.99403 5.09119 10.5746 5.42516 11.2132C5.75914 11.7938 6.31576 12.2002 7.09503 12.3163H7.15069C8.48659 12.3744 9.54417 11.6777 9.87814 10.4004C9.93381 10.1101 9.98947 9.81986 10.0451 9.47151V9.29734C10.0451 9.00705 10.1008 8.77482 10.1565 8.54259C10.1565 8.54259 10.1565 8.48453 10.1565 8.42647C10.2121 8.60065 10.2678 8.77482 10.2678 8.83288L10.3234 9.29734V9.47151C10.5461 11.3294 9.2102 12.8969 7.31768 13.1291Z" />
  </StyledSVG>
)

EvacuatorIcon.propTypes = {
  sizeVariation: PropTypes.oneOf(Object.keys(sizeVariations)),
  colorName: PropTypes.string,
}

EvacuatorIcon.defaultProps = {
  sizeVariation: '14px mobile',
  colorName: 'Primary Dark Blue',
}
